.main-container {
  min-height: 50vh;
}
.central-form {
  max-width: 1100px;
  flex-direction: column;
  padding: 10px;
  border-radius: 12px;
  margin: 0 auto;
  background-color: #1f2235;
}
.central-form h2 {
  color: white;
  letter-spacing: 0.1rem;
}
.back-form {
  display: flex;
}
.back-form .img-back {
  width: 100%;
  margin: 0 0 20px 0;
}
.img-back h4 {
  color: white;
  letter-spacing: 0.3rem;
  font-weight: 400;
  font-size: 18px;
  opacity: 0.4;
}
.back-form .img-back img {
  width: 100%;
  opacity: 0.5;
}
.central-form form {
  width: auto;
  display: flex;
  color: #111;
  padding: 15px;
  flex-direction: column;
  margin: -52px 0 0 0;
  background-color: white;
  border-radius: 12px;
}
.contact-info {
  border: 2px solid rgba(0, 0, 0, 0);
  outline: none;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  text-align: center;
  width: 50%;
}
.phone {
  color: white;
  margin-left: auto;
}
form p {
  text-align: center;
  margin: 0 0 10px 0;
  color: var(--dark-orange);
  letter-spacing: 0.4rem;
  font-family: "Poppins Bold";
}
form label {
  margin: 0 0 5px 0;
  color: black;
  letter-spacing: 0.2rem;
}
form input,
textarea {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0);
  outline: none;
  background-color: rgba(230, 230, 230, 0.6);
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  margin: 0 0 22px 0;
  border-radius: 10px;
  transform: 0.3s;
}
textarea:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: white;
}
input:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: white;
}
form button {
  margin: 15px 0 0 0;
  padding: 5px;
  outline: none;
  width: 160px;
  border: 2px solid var(--dark-orange);
  font-size: 18px;
  border-radius: 19px;
  background-color: #1f2235;
  color: white;
  align-items: center;
  justify-content: center;
}
form button:hover {
  background-color: #1f2235;
  transition: 1s;
  border: 2px solid #1f2235;
  background-color: var(--dark-orange);
}
button .load {
  width: 100%;
}

button .load img {
  width: 60px;
  color: green;
}
.img-back {
  flex: 1;
  align-self: center;
  margin: 0 20px 0 0;
}
form {
  flex: 0.9;
}
.col i {
  padding: 7px;
  color: white;
}
.col i:hover {
  transform: scale(1.5);
}
.send-btn {
  cursor: pointer;
}

@media screen and (max-width: 1140px) {
  .back-form {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .img-back {
    flex: 1;
    align-self: center;
    margin: 0 20px 0 0;
  }
  form {
    flex: 0.9;
  }
}
@media only screen and (max-width: 466px) {
  .contact-info {
    display: flex;
    flex-direction: column;
  }

  .contact-info h2 {
    font-size: 15px;
  }
  .contact-info h3 {
    font-size: 12px;
  }
}

.scroll-container {
  position: absolute;
  text-align: center;
  right: 0;
  margin-right: 20%;
  overflow: hidden;
  flex-direction: column;

  z-index: 100;
  cursor: pointer;
}
@media only screen and (min-width: 475px) {
  .scroll-container {
    /* margin-bottom: 40px; */
    margin-right: 5%;
  }
}
@media only screen and (min-width: 320px) {
  .scroll-container {
    margin-top: -65px;
    margin-right: -20px;
  }
}
@media only screen and (max-width: 320px) {
  .scroll-container {
    transform: scale(0.7);
    margin-right: -20;
  }
}
@media only screen and (max-width: 460px) {
  .btn-scroll {
    transform: scale(0.6);
  }
}

.btn-scroll {
  width: 50px;
  height: 50px;
  color: aliceblue;
  margin-right: 20px;
  font-size: 24px;
  background-color: tomato;
  border-radius: 10px;
  border: none;
}
.btn-scroll i:hover {
  transform: rotate(360deg);
  color: aliceblue;
}

.btn-scroll i {
  -webkit-animation: mover 0.5s infinite alternate;
  animation: mover 0.5s infinite alternate;
}
@keyframes mover {
  100% {
    transform: translateY(-5px);
  }
  0% {
    transform: translateX(0px);
  }
}
